import { HttpTransportType, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { findIndex, max, uniq } from 'lodash';
import urlcat from 'urlcat';
import { proxy, ref } from 'valtio';
import { devtools } from 'valtio/utils';
import { apirc } from '~/configs/apirc';
import { ENV } from '~/configs/ENV';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { signalrConnectionBuilder } from '~/modules/SDK/Signalr/signalrConnectionBuilder';
import { SignalrEvent } from '~/modules/SDK/Signalr/SignalrEvent';
import { SignalrMethod } from '~/modules/SDK/Signalr/SignalrMethod';
import { __DEV__ } from '~/utils/__DEV__';
/**
 * # signalr/core@2
 *
 * - Proxy 版本的 {@link useSignalrStore} 重製
 * - 取代 {@link useSignalrStore} 和 一堆的 {@link useSignalrStoreValueOHLC}
 */
export const signalrStore2 = proxy(new (class {
    /** # 唯一的 Signalr 實體(instance) */
    connection;
    state;
    constructor() {
        debugAPI.signalrV2.log(`建立單例實體`);
        this.connection = ref(signalrConnectionBuilder
            .withUrl(urlcat(apirc.signalr.wsURL.baseUrl, {
            version: 'signalrStore2',
            commithash: ENV.COMMITHASH,
        }), {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            logger: LogLevel.Information,
        })
            .build());
        // handle 所有後端推送值
        this.connection.on(SignalrEvent.Quote, this.handleQuoteUpdate);
        this.connection.on(SignalrEvent.BidAsk, this.handleBidaskUpdate);
        this.connection.on(SignalrEvent.TradeInfo, this.handleTradeInfoUpdate);
        this.connection.on(SignalrEvent.Tick, this.handleTickUpdate);
        this.connection.onclose(error => {
            debugAPI.signalrV2.log(`event:連線已斷開`, { error });
        });
        this.connection.onreconnecting(error => {
            debugAPI.signalrV2.log(`event:正在重新連線`, { error });
            this.stop();
        });
        // log
        this.connection.invoke = new Proxy(this.connection.invoke, {
            apply(target, connection, args) {
                debugAPI.signalrV2.log(`.invoke(); ing...`, ...args);
                return Reflect.apply(target, connection, args);
            },
        });
        // proxy(state)
        const state = (this.state = proxy({
            connectionState: this.connection.state,
            /** 是否已連線 */
            get isConnected() {
                return state.connectionState === HubConnectionState.Connected;
            },
        }));
    }
    /** @deprecated 使用 signalrStore2.state.isConnected */
    get isConnected() {
        return signalrStore2.connection.state === HubConnectionState.Connected;
    }
    /**
     * # 前端訂閱清單
     *
     * ### 若有重複的 string 在列表中，則代表「不只一個組件發出訂閱請求」
     *
     * 例如若值為以下
     *
     *     QuoteSymbols: ['TX-1', 'TX-1', 'GC-1']
     *
     * 則代表有兩個以上的組件皆訂閱 `'TX-1'` 這個 symbol
     *
     * 當以上的「其中一個組件」unsub `'TX-1'` 時
     *
     * 其值應為
     *
     *     QuoteSymbols: ['TX-1', 'GC-1']
     *
     * 代表意義：只 unmount/unsub 其中一個組件報價，另一個組件則繼續訂閱
     */
    subscriptions = proxy({
        QuoteSymbols: [],
        BidAskSymbols: [],
        QuoteTopics: [],
        TradeInfoTopics: [],
        TickSymbols: [],
    });
    /** # 後端推送值 */
    values = {
        quote: {},
        bidask: {},
        tradeInfo: {},
        tick: {},
    };
    /** @deprecated */
    get isDisconnected() {
        return signalrStore2.connection.state === HubConnectionState.Disconnected;
    }
    /** # 開始連線 */
    async start() {
        const log = debugAPI.signalrV2.logger.extend(`${signalrStore2.start.name}()`);
        log(`.start(); ing; 正在建立連線`);
        try {
            await signalrStore2.connection.start().catch((error) => {
                console.error(error.message);
            });
            log(`.start(); ed; 已建立連線`);
        }
        catch (error) {
            throw new Error(`無法建立連線`, { cause: error });
        }
    }
    async stop() {
        debugAPI.signalrV2.log(`.stop() ing; 正在中止連線`);
        await signalrStore2.connection.stop();
        debugAPI.signalrV2.log(`.stop() ed; 已中止連線`);
    }
    handleQuoteUpdate(data) {
        signalrStore2.values.quote[data.symbol] = data;
    }
    handleBidaskUpdate(data) {
        const totalAskQty = data.askQty1 + data.askQty2 + data.askQty3 + data.askQty4 + data.askQty5;
        const totalBidQty = data.bidQty1 + data.bidQty2 + data.bidQty3 + data.bidQty4 + data.bidQty5;
        signalrStore2.values.bidask[data.symbol] = {
            ...data,
            totalQty: totalAskQty + totalBidQty,
            totalAskQty,
            totalBidQty,
            maxQty: max([
                data.askQty1,
                data.askQty2,
                data.askQty3,
                data.askQty4,
                data.askQty5,
                data.bidQty1,
                data.bidQty2,
                data.bidQty3,
                data.bidQty4,
                data.bidQty5,
            ]) ?? 0,
        };
    }
    handleTradeInfoUpdate(data) {
        signalrStore2.values.tradeInfo[data.symbol] = data;
    }
    handleTickUpdate(data) {
        signalrStore2.values.tick[data.symbol] = data;
    }
    /** 送出「前端維護的訂閱清單」給後端知道 */
    async invokeSubscriptions() {
        /** 以 `uniq()` 的方式，避免送到重複 symbol */
        await signalrStore2.connection
            .invoke(SignalrMethod.Subscribe, {
            BidAskSymbols: uniq(signalrStore2.subscriptions.BidAskSymbols),
            QuoteSymbols: uniq(signalrStore2.subscriptions.QuoteSymbols),
            QuoteTopics: uniq(signalrStore2.subscriptions.QuoteTopics),
            TickSymbols: uniq(signalrStore2.subscriptions.TickSymbols),
            TradeInfoTopics: uniq(signalrStore2.subscriptions.TradeInfoTopics),
        })
            .catch((error) => {
            console.error(error.message);
        });
    }
    async addQuote(symbols) {
        const $symbols = ensureAsArray(symbols);
        signalrStore2.subscriptions.QuoteSymbols = [
            ...signalrStore2.subscriptions.QuoteSymbols,
            ...$symbols,
        ];
    }
    async removeQuote(symbols) {
        const $symbols = ensureAsArray(symbols);
        removeRefSymbols(signalrStore2.subscriptions.QuoteSymbols, $symbols);
        await invokeSymbols(signalrStore2.subscriptions.QuoteSymbols, $symbols, 'QuoteSymbols');
    }
    async removeAllQuotes() {
        await signalrStore2.connection
            .invoke(SignalrMethod.Unsubscribe, {
            QuoteSymbols: signalrStore2.subscriptions.QuoteSymbols,
        })
            .catch((error) => {
            console.error(error.message);
        });
        signalrStore2.subscriptions.QuoteSymbols = [];
    }
    async addQuoteTopic(symbols) {
        const $symbols = ensureAsArray(symbols);
        signalrStore2.subscriptions.QuoteTopics = [
            ...signalrStore2.subscriptions.QuoteTopics,
            ...$symbols,
        ];
    }
    async removeQuoteTopic(symbols) {
        const $symbols = ensureAsArray(symbols);
        removeRefSymbols(signalrStore2.subscriptions.QuoteTopics, $symbols);
        await invokeSymbols(signalrStore2.subscriptions.QuoteTopics, $symbols, 'QuoteTopics');
    }
    async addBidask(symbols) {
        const $symbols = ensureAsArray(symbols);
        signalrStore2.subscriptions.BidAskSymbols = [
            ...signalrStore2.subscriptions.BidAskSymbols,
            ...$symbols,
        ];
    }
    async removeBidask(symbols) {
        const $symbols = ensureAsArray(symbols);
        removeRefSymbols(signalrStore2.subscriptions.BidAskSymbols, $symbols);
        await invokeSymbols(signalrStore2.subscriptions.BidAskSymbols, $symbols, 'BidAskSymbols');
    }
    async addTradeInfoTopic(symbols) {
        const $symbols = ensureAsArray(symbols);
        signalrStore2.subscriptions.TradeInfoTopics = [
            ...signalrStore2.subscriptions.TradeInfoTopics,
            ...$symbols,
        ];
    }
    async removeTradeInfoTopic(symbols) {
        const $symbols = ensureAsArray(symbols);
        removeRefSymbols(signalrStore2.subscriptions.TradeInfoTopics, $symbols);
        await invokeSymbols(signalrStore2.subscriptions.TradeInfoTopics, $symbols, 'TradeInfoTopics');
    }
    async addTick(symbols) {
        const $symbols = ensureAsArray(symbols);
        signalrStore2.subscriptions.TickSymbols = [
            ...signalrStore2.subscriptions.TickSymbols,
            ...$symbols,
        ];
    }
    async removeTick(symbols) {
        const $symbols = ensureAsArray(symbols);
        removeRefSymbols(signalrStore2.subscriptions.TickSymbols, $symbols);
        await invokeSymbols(signalrStore2.subscriptions.TickSymbols, $symbols, 'TickSymbols');
    }
})());
const ensureAsArray = (symbols) => !symbols ? [] : Array.isArray(symbols) ? symbols : [symbols];
const removeRefSymbols = (symbols, removing) => {
    for (const $removing of removing) {
        symbols.splice(findIndex(symbols, symbol => symbol === $removing), 1);
    }
};
const invokeSymbols = async (symbols, removing, target) => {
    const symbolsShouldRemove = [];
    for (const $removing of removing) {
        if (!symbols.includes($removing)) {
            symbolsShouldRemove.push($removing);
        }
    }
    await signalrStore2.connection
        .invoke(SignalrMethod.Unsubscribe, {
        [target]: symbolsShouldRemove,
    })
        .catch((error) => {
        console.error(error.message);
    });
};
if (__DEV__) {
    devtools(signalrStore2, { name: 'signalrStore2' });
}
